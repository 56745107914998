<template>
  <div class="page__wrapper">
    <ag-grid
      ref="financeGrid"
      style="width: 100%; height: 100%"
      pagination
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
          <el-button
            v-permission="'dealer_finance:templateDownload'"
            type="primary"
            @click="handleTemplateClick"
          >
            {{ $t("operation.templateDownload") }}
          </el-button>
          <el-button
            v-permission="'dealer_finance:import'"
            type="primary"
            @click="handleImportClick"
          >
            {{ $t("operation.import") }}
          </el-button>
          <el-button
            v-permission="'dealer_finance:batchImport'"
            type="primary"
            @click="isShowBatchDialog = true"
          >
            {{ $t("operation.batchImport") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>

    <!-- 详情页 -->
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 New -->
    <new-detail-dialog
      :show-flag.sync="isShowNewDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 2022 -->
    <detail-2022-dialog
      :show-flag.sync="isShowDetail2022Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202206 -->
    <detail-202206-dialog
      :show-flag.sync="isShowDetail202206Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202308 -->
    <detail-202308-dialog
      :show-flag.sync="isShowDetail202308Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 文件上传 -->
    <!-- <simple-upload
      ref="uploader"
      :params="{ bizStatus: 'V1' }"
      url="/jlr/tt/selfExaminationData/importByEntity"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    /> -->
    <simple-upload
      ref="uploader"
      :params="{ bizStatus: 'V1' }"
      url="/download/tt/selfExaminationData/importByEntity"
      :show-flag.sync="isShowUploader"
      @on-success="handleUploadSuccess"
    />

    <!-- 批量上传 -->
    <simple-upload
      ref="bacthUploader"
      multiple
      :params="batchImportParams"
      :show-confirm="true"
      url="/jlr/tt/selfExaminationData/importByEntity"
      :show-flag.sync="isShowBatchUploader"
      @confirm="handleBatchImportConfirm"
    />

    <!-- 模板下载弹出框 -->
    <el-dialog
      append-to-body
      :visible.sync="isShowTemplateDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="handleTmDialogOpen"
      @closed="handleTmDialogClosed"
    >
      <div
        slot="title"
        class="detail-dialog__header"
      >
        {{ $t("dealer_finance.balance.templateTitle") }}
      </div>

      <simple-form
        ref="tmForm"
        v-model="tmFormModel"
        label-width="80px"
        :form-field="tmFormField"
        :view-flag="false"
      />

      <!-- Footer -->
      <div slot="footer">
        <el-button @click="isShowTemplateDialog = false">
          {{ $t("operation.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="handleTemplateDownload"
        >
          {{ $t("operation.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 批量导入弹出框 -->
    <el-dialog
      append-to-body
      :visible.sync="isShowBatchDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="handleBatchDialogOpen"
      @closed="handleBatchDialogClosed"
    >
      <div
        slot="title"
        class="detail-dialog__header"
      >
        {{ $t("operation.batchImport") }}
      </div>

      <simple-form
        ref="biForm"
        v-model="biFormModel"
        label-width="80px"
        :form-field="biFormField"
        :view-flag="false"
      />

      <!-- Footer -->
      <div slot="footer">
        <el-button @click="isShowBatchDialog = false">
          {{ $t("operation.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="handleBatchConfirm"
        >
          {{ $t("operation.confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import detailDialog from './detail'
import newDetailDialog from './detailNew'
import detail2022Dialog from './detail2022'
import detail202206Dialog from './detail202206'
import detail202308Dialog from './detail202308'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  template: '/jlr/tt/selfExaminationData/importTemplate',
  delete: '/system/user/delete',
  office: '/system/office/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleViewClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.view')">
        <el-button v-permission="'dealer_finance:view'" type="text" icon="el-icon-search" @click="handleViewClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'DealerFinance',
  components: {
    detailDialog,
    newDetailDialog,
    detail2022Dialog,
    detail202206Dialog,
    detail202308Dialog
  },
  mixins: [pageMixins],
  data () {
    return {
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      tableData: [],
      rowData: {},
      statusList: [],
      tmFormModel: {},
      biFormModel: {},
      gridOptions: {},
      isViewFlag: false,
      isShowUploader: false,
      batchImportParams: {},
      isShowBatchUploader: false,
      isShowDetailDialog: false,
      isShowBatchDialog: false,
      isShowTemplateDialog: false,
      frameworkComponents: {},
      isShowNewDetailDialog: false,
      isShowDetail2022Dialog: false,
      isShowDetail202206Dialog: false,
      isShowDetail202308Dialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleViewClick: this.handleViewClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: false }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 20, sm: 20, md: 20 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    tmFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 12, sm: 10, md: 8 },
          label: this.$t('dealer_finance.month'),
          component: { type: 'month', valueFormat: 'yyyyMM', clearable: true },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        }
      ]
    },
    biFormField () {
      return [
        {
          prop: 'bizStatus',
          type: 'Select',
          col: { xs: 12, sm: 10, md: 8 },
          label: this.$t('thirdParty_reportExport.status'),
          component: {
            optionList: this.statusList,
            valueKey: 'id',
            clearable: true
          },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          }
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 260
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'sys_office_region',
              value: params.value
            }),
          minWidth: 80
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: (params) =>
            params.data.dealerParent ? params.data.dealerParent.name || '' : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA && valueB
              ? valueA.name.localeCompare(valueB.name)
              : -1
          },
          minWidth: 150
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 130
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: (params) =>
            params.value
              ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月')
              : '',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 260
        },
        {
          headerName: this.$t('dealer_finance.createDate'),
          field: 'createDate',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_self_examination_data_head_biz_status',
              value: params.value
            }),
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_finance.isSubmit'),
          field: 'isSubmit',
          valueFormatter: (params) =>
            this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 90
        },
        {
          headerName: this.$t('field.operation'),
          width: 68,
          suppressSizeToFit: true,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
    const statusList = this.$getDictList(
      'tt_self_examination_data_head_biz_status'
    )
    this.statusList = statusList.filter(
      (ele) => ele.value === 'V1' || ele.value === 'V4'
    )
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign(
        {},
        this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      )
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V1' })
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.list, params)
        .then((resp) => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202307', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    handleImportClick () {
      this.isShowUploader = true
    },
    handleTemplateClick () {
      this.isShowTemplateDialog = true
    },
    handleTmDialogOpen () { },
    handleTmDialogClosed () {
      this.tmFormModel = {}
      this.$refs.tmForm.resetFields()
    },
    handleTemplateDownload (row) {
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$refs.tmForm.$refs.simpleForm.validate((status) => {
        if (status) {
          const userInfo = this.$store.state.app.userInfo
          const params = {
            dealer: { id: userInfo.company.id },
            period: this.tmFormModel.period,
            bizStatus: 'V3'
          }
          this.$axios
            .post(BASEURL.template, params)
            .then((resp) => {
              this.$message({
                type: 'success',
                message: this.$t('tip.exportSuccess')
              })
            })
            .finally(() => {
              loadingFlag.close()
            })
          this.isShowTemplateDialog = false
        }
      })
    },
    handleBatchDialogOpen () { },
    handleBatchDialogClosed () {
      this.biFormModel = {}
      this.$refs.biForm.resetFields()
    },
    handleUploadSuccess () {
      this.isShowUploader = false
      this.handleDataSearch()
    },
    handleBatchConfirm () {
      this.$refs.biForm.$refs.simpleForm.validate((status) => {
        if (status) {
          this.batchImportParams = {
            bizStatus: this.biFormModel.bizStatus
          }
          this.isShowBatchUploader = true
        }
      })
    },
    handleBatchImportConfirm () {
      this.isShowBatchDialog = false
      this.isShowBatchUploader = false
      this.handleDataSearch()
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
